.Sidebar__folderdetail {
    display: flex;
    flex-direction: column;
    /* padding: 20px; */
}

.btn__back {
    margin: 0px 20px 20px 20px;
    padding: 15px 30px;
    background-color: transparent;
    border: 1px solid black;
    cursor: pointer;
}

.folderdetail {
    padding: 0px;
    margin: 0px 20px 0px 0px;
    text-align: right;
    font-weight: normal;
    font-size: 18px;
}