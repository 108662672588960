.Sidebar__folderlist {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* padding: 20px; */
}

.folderlist {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.link__addfolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}

.btn__addfolder {
    margin: 20px;
    padding: 15px 30px;
    background-color: transparent;
    border: 1px solid black;
    cursor: pointer;
    width: 80%;
}