.Note {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end
}

.Note__title {
    padding: 0px;
    margin: 0px 0px 30px 0px;
}

.Note__datemodified {
    font-size: 14px;
    padding: 0px;
    margin: 0px;
}

.btn__delete {
    padding: 15px 30px;
    background-color: transparent;
    border: 1px solid black;
    width: 150px;
    cursor: pointer;
}

.deleteNoteError {
    color: darkorchid;
    font-size: 12px;
}