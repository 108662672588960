/* .NoteList__container {
    height: 100%;
} */

.NoteList {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.NoteList li {
    border: 1px solid black;
    margin-bottom: 10px;
    padding: 20px;
}

.btn__addnote {
    margin-top: 10px;
    padding: 15px 30px;
    background-color: transparent;
    border: 1px solid black;
    width: 200px;
    cursor: pointer;
}