.folder {
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    text-align: center;
    padding: 20px;
}

.folder__name {
    font-weight: normal;
    padding: 0px;
    margin: 0px;
    font-size: 18px;
}

.active li {
    background-color: aquamarine;
}

li:first-child {
    border-top: 1px solid black;
}
