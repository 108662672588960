header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20vh;
  border-bottom: 1px solid black;
}

main {
  display: flex;
  flex-direction: row;
}

.Sidebar {
  flex: 1;
  padding: 20px;
  margin: 0px;
}

.MainContent {
  flex: 4;
  padding: 20px;
  margin: 0px;
}

.NoteList {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.NoteList li {
  border: 1px solid black;
  margin-bottom: 10px;
  padding: 20px;
}

.Note {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end
}

.Note__title {
  padding: 0px;
  margin: 0px 0px 30px 0px;
}

.Note__datemodified {
  font-size: 14px;
  padding: 0px;
  margin: 0px;
}

.btn__delete {
  padding: 15px 30px;
  background-color: transparent;
  border: 1px solid black;
  width: 150px;
  cursor: pointer;
}

.btn__addnote {
  margin-top: 10px;
  padding: 15px 30px;
  background-color: transparent;
  border: 1px solid black;
  width: 200px;
  cursor: pointer;
}

.Error {
    color: red;
    font-size: 18px;
}