.AddNote__form {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 40px;
}

.form__title {
    margin: 0px 0px 30px 0px;
    padding: 0px;
}

.form__group {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.form__group label {
    margin-bottom: 10px;
}

.name-input {
    max-width: 80%;
    padding: 10px 10px;
}

.content-input {
    max-width: 80%;
    min-height: 150px;
    padding: 10px 10px;
    border: 1px solid rgb(200, 200, 200);
}

.folder-input {
    max-width: 40%;
}

.AddNote__buttons button {
    padding: 10px 20px;
    min-width: 100px;
    background-color: transparent;
    border: 1px solid black;
    cursor: pointer;
}

.AddNote__buttons button:first-child {
    margin-right: 10px;
}

.AddNote__error {
    margin-top: 5px;
    color: darkorchid;
    font-size: 12px;
}